/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

:root {
  --primary-color: #343434;
  --background-color: #292929;
  --text-color: #fff;
  --gray-1: #919191;
  --gray-2: #999999;
  --warning-text: #ad952e;
  --danger-text: #ff645d;
  --btn-primary: #626262;
  --btn-primary-hover: #999999;
  --load-martrix-container: #343434;
  --dropdown-text-color: #fff;
  --table: #292929;
  --th: #dfdfdf;
  --td: #686868;
  --td-hover: #686868;
  --th-bg: #3e3e3e;
  --table-border: #414141;
  --modal-text-color: #dfdfdf;
  --info-card-text: #919191;
  --danger: #ff645d;
  --warning: #fd9e1e;
  --success: #4ac357;
  --purple: #8097f5;
  --red: #ff645df6;
  --yellow: #af7024;
  --text-yellow-matrix: #af7024;
  --box-shadow: #919191;
}

.light-theme {
  --primary-color: #3897f4;
  --background-color: #f5f5f5;
  --text-color: #000;
  --gray-1: #333333;
  --gray-2: #999999;
  --warning-text: #ad952e;
  --danger-text: #ff645d;
  --btn-primary: #3897f4;
  --btn-primary-hover: #4e9fee;
  --load-martrix-container: #fff;
  --dropdown-text-color: #333333;
  --table: #fff;
  --th: #dfdfdf;
  --td: #686868;
  --td-hover: #fff;
  --th-bg: #3897f4;
  --table-border: #3897f4;
  --modal-text-color: #333;
  --info-card-text: #fff;
  --danger: #ff645d;
  --warning: #fd9e1e;
  --success: #4ac357;
  --purple: #fff;
  --red: #fff;
  --yellow: #fff;
  --text-yellow-matrix: #af7024;
  --box-shadow: #3897f4;
}

body {
  background-color: var(--background-color) !important;
  color: var(--text-color);
}

.text-red {
  color: var(--red) !important;
}
.text-green {
  color: var(--success) !important;
}
.text-yellow {
  color: var(--yellow) !important;
}
.text-yellow-matrix {
  color: var(--text-yellow-matrix) !important;
}
.text-purple {
  color: var(--purple) !important;
}
.text-grey{
  color: var(--gray-1) !important;
}

.cursor-pointer {
  cursor: pointer;
}
.pe-none {
  pointer-events: none;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.pending {
  background-color: var(--danger) !important;
  p {
    color: #fff !important;
  }
}
.detained {
  background-color: var(--warning) !important;
  p {
    color: #fff !important;
  }
}
.goAhead {
  background-color: var(--success) !important;
  p {
    color: #fff !important;
  }
}
// buttons
.btn-primary {
  background-color: var(--btn-primary) !important;
  border-color: var(--btn-primary) !important;
  &:hover {
    background-color: var(--btn-primary-hover) !important;
  }
}
.btn-secondary {
  background-color: transparent !important;
  border-color: var(--btn-primary) !important;
  color: var(--gray-1) !important;
  &:hover {
    background-color: var(--btn-primary-hover) !important;
    color: #fff !important;
  }
}

.font-10{
  font-size: 10px !important;
}

.font-12{
  font-size: 12px !important;
}

// header
.navbar {
  background-color: var(--primary-color);
  padding: 10px !important;
  position: sticky !important;
  top: 0;
  z-index: 2;
  .dropdown {
    .dropdown-menu {
      left: -170px !important;
      min-width: 190px !important;
      background-color: var(--background-color);
      padding: 0;
      box-shadow: 0 0.5rem 1rem var(--load-martrix-container);
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-item {
      color: var(--dropdown-text-color);
      &:hover {
        background-color: var(--primary-color);
      }
    }
    .profile-avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid var(--gray-2);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
    }
    button {
      i {
        font-size: 16px;
        width: 25px;
      }
    }
  }
  i {
    font-size: 25px;
    cursor: pointer;
  }
}
// authentication
.bg {
  background-image: url("./assets/images/bg.png");
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-card {
  max-width: 540px;
  width: 100%;
  background-color: rgba(41, 41, 41, 0.8);
  border-radius: 8px;
  h2,
  p {
    color: #fff;
  }
  .form-control {
    background-color: transparent !important;
    border-radius: 0;
    border: 0 !important;
    border-bottom: 2px solid #fff !important;
    box-shadow: none !important;
    color: #fff !important;
    padding-left: 40px;
  }
  ::placeholder {
    color: #fff !important;
  }
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    border: 0;
  }
  .input-box {
    position: relative;
    margin-bottom: 40px;
    i {
      position: absolute;
      color: #fff;
      top: 10px;
      left: 12px;
    }
    .fa-envelope {
      top: 10px;
    }
  }
  .link {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
// dashboard
.dashboard-container {
  padding: 20px;
  margin-top: 20px;
  .green-border {
    border-left: 2px solid #4ac357;
    border-right: 2px solid #4ac357;
    padding: 0 20px;
  }
  .red-border {
    border-left: 2px solid #ff645d;
    border-right: 2px solid #ff645d;
    padding: 0 20px;
  }
  .seperators {
    padding: 0 20px;
    hr {
      border: 1px solid var(--gray-2);
    }
  }
  .form-control {
    border: 0;
    background-color: transparent;
    text-align: center;
    color: var(--info-card-text);
    &:focus {
      background-color: transparent;
      box-shadow: none;
      color: var(--text-color);
    }
  }
  h2 {
    color: var(--gray-1);
    font-size: 20px;
  }
  .table-responsive {
    border-radius: 8px;
    outline: 2px solid var(--table-border);
    table.port-load-table {
      margin: 0;
      --bs-table-bg: transparent;
      border-radius: 8px;
      tr {
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: var(--primary-color) !important;
          cursor: pointer;
          // color: #000;
        }
        &:hover td {
          color: var(--td-hover);
        }
      }
      th {
        color: var(--th);
        background-color: var(--th-bg);
        text-transform: uppercase;
        font-weight: 400;
        padding: 0.75rem 0.5rem;
      }
      td {
        color: var(--td);
        text-transform: uppercase;
        padding: 0.75rem 0.5rem;
      }
    }
  }
}

.info-box {
  text-align: center;
  p {
    color: var(--gray-1);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }
  hr {
    // width: 250px;
    margin: 2rem auto; /* Centers the hr */
    border: 1px solid var(--gray-2);
  }
  .elevated-card {
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
  .info-card-default {
    background-color: transparent !important;
    p {
      color: var(--gray-1) !important;
    }
  }
  .info-card {
    background-color: var(--primary-color);
    padding: 4px 10px;
    // max-width: 250px;
    margin: 0 auto;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: var(--info-card-text);
    }
  }
}

.matrix-container {
  border-radius: 8px;
  background: linear-gradient(
    145deg,
    var(--load-martrix-container),
    var(--load-martrix-container)
  );
  box-shadow: inset 3px 3px 6px var(--box-shadow),
    inset -3px -3px 6px var(--box-shadow);
  padding: 10px 0;
  // background: linear-gradient(145deg, #F5F5F4, #fff);
  // box-shadow: inset 3px 3px 6px #20c997, inset -3px -3px 6px #20c997;
}
.matrix-cell {
  // background: #333;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  transition: 0.3s ease-in;
  //   display: flex;
  hr {
    width: 30px;
    margin: 1rem auto 0;
    border: 1px solid var(--gray-2);
  }
}

.load-id {
  font-size: 14px;
  color: var(--gray-2);
  font-weight: 500;
  transition: 0.3s ease-in;

  &:hover {
    background-color: var(--background-color);
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.load-id.yellow {
  color: var(--warning-text);
}

.load-id.red {
  color: var(--danger-text);
}

.adjustable-text {
  margin-top: -50px;
  margin-bottom: 50px;
  color: var(--gray-1);
  div {
    font-size: 12px;
  }
}
.adjustable-text-snapshot {
  margin-top: -100px;
  margin-bottom: 50px;
  color: var(--gray-1);
  div {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .adjustable-text,
  .adjustable-text-snapshot {
    margin-top: 0;
  }
}

// modals
.modal-content {
  background-color: var(--background-color) !important;
  h4 {
    font-size: 20px;
  }
  h4,
  label {
    color: var(--modal-text-color);
  }
  .form-control {
    background-color: transparent;
    border: 1px solid var(--modal-text-color);
    padding: 0.75rem !important;
    color: var(--modal-text-color) !important;
    &:focus {
      background-color: transparent;
      box-shadow: none;
      border-color: var(--modal-text-color);
    }
  }
  ::placeholder {
    color: var(--modal-text-color) !important;
  }
  .modal-header {
    border-bottom: 0;
    i {
      font-size: 20px;
      color: var(--modal-text-color);
      cursor: pointer;
    }
  }
  .modal-footer {
    border-top: 0;
  }
  .modal-body {
    p {
      color: var(--gray-1);
    }
  }
}

.form-control:disabled {
  background-color: #6a6767 !important;
}

.light-theme .form-control:disabled {
  background-color: #d1d5d8 !important;
}

.lookup-card {
  background-color: var(--primary-color);
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  h6 {
    margin: 0;
    color: var(--info-card-text);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    color: var(--info-card-text);
  }
}
.lookup-table {
  h6 {
    margin: 0;
    color: var(--gray-1);
  }
  i {
    color: var(--gray-1);
    cursor: pointer;
  }
  button {
    i {
      color: #fff;
    }
  }
  .form-control {
    border: 1px solid var(--btn-primary);
    text-align: left;
    padding: 0.25rem;
    color: var(--gray-1);
  }
  .edit-mode td {
    padding: 0.5rem !important;
  }
  table {
    margin: 0;
    --bs-table-bg: transparent;
    border-radius: 8px;

    th {
      color: var(--th);
      background-color: var(--th-bg);
      text-transform: uppercase;
      font-weight: 400;
      padding: 0.75rem 0.5rem;
    }
    td {
      color: var(--td);
      text-transform: uppercase;
      padding: 0.75rem 0.5rem;
    }
  }
  table {
    thead {
      background-color: var(--gray-3);
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  .table-height {
    height: calc(100vh - 265px);
    overflow: auto;
  }
}

.pagination {
  display: inline-flex !important;
  box-shadow: var(--box-shadow) !important;
  --bs-pagination-border-radius: 50% !important;
  .page-link {
    background-color: var(--load-martrix-container);
    color: var(--gray-1);
    border: 0;
    &:hover {
      background-color: var(--gray-2);
      color: #fff;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .active > .page-link {
    background-color: var(--btn-primary-hover) !important;
  }
}

.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  top: 71px;
}
.loader::after {
  content: "";
  width: 400px;
  height: 4.8px;
  background: var(--box-shadow);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}

.spinner-component {
  display: none;
}
body.loading {
  div.spinner-component {
    display: block;
  }
}

// AuthLoader

.lds-roller {
  zoom: 0.45 !important;
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ffffff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.not-visible {
  visibility: hidden;
}
